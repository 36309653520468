import React from 'react';
import LoginForm from '../LoginForm';
import NavBar from '../NavBar';

const LoginPage = () => {
    return (
        <>
            <NavBar />

            <LoginForm />
        </>
    );
}

export default LoginPage;