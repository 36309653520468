import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useEffect } from "react";
import { Card } from "react-bootstrap";
import { ReviewCardModalData } from "./ReviewCardModal";
import { formatDateStr } from "../helpers/DateHelper";

type ReviewProps = {
  id: string;
  title?: string;
  review: string;
  rating: number;
  date: string;
  author: string;
  maxAuthorLength: number;
  onClickHandler: (review: ReviewCardModalData) => void;
};

const ReviewCard = ({
  id,
  title,
  review,
  rating,
  date,
  author,
  maxAuthorLength,
  onClickHandler,
}: ReviewProps) => {
  const [reviewText, setReviewText] = useState<string>(review);
  const reviewLengthCutoff = 170; // What is a reasonable cutoff?
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    if (review?.length > reviewLengthCutoff) {
      setReviewText(review.substring(0, reviewLengthCutoff).trimEnd() + "...");
    }
  }, [review]);

  const reviewCardModalData = {
    id: id,
    title: title,
    reviewText: review,
    rating: rating,
    createdDate: date,
    author: author,
  };

  const getAuthorText = (author: string) => {
    if (author.length > maxAuthorLength) {
      return author.substring(0, maxAuthorLength) + "...";
    }
    return author;
  };

  return (
    <Card onClick={() => onClickHandler(reviewCardModalData)}>
      <Card.Header className="review-header mt-2">
        <Card.Title>
          <span className="review-title">{title}</span>
        </Card.Title>
        <div style={{ fontSize: "0.9rem" }}>
          <span style={{ fontWeight: 200, fontSize: "1.3rem" }}>{rating}</span>
          <span>/10</span>
        </div>
      </Card.Header>
      <Card.Body className="mt-0 pt-0">
        <Card.Text>{reviewText}</Card.Text>
      </Card.Body>
      <Card.Footer style={{ fontSize: "0.9rem" }}>
        <small style={{ float: "left" }} className="text-muted review-date">
          {formatDateStr(date)}
        </small>
        <small style={{ float: "right" }} className="float-end text-muted">
          {getAuthorText(author)}
          <FontAwesomeIcon
            style={{ fontSize: "0.8rem", marginLeft: "8px" }}
            icon={faUser}
            inverse
          />
        </small>
      </Card.Footer>
    </Card>
  );
};

export default ReviewCard;
