import React from 'react';
import { Button, Form, Container } from 'react-bootstrap';
import '../forms.css';

const LoginForm = () => {
    return (
        <Container>
            <h2 className="text-center mt-5 mb-4">Login</h2>
            <div className="form-container pt-2 pb-2">
                <Form className="hall-review-form">
                    <div className="mb-3">
                        <label className="form-label">Email</label>
                        <input type="email" className="form-control" id="login-email" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Password</label>
                        <input type="password" className="form-control" id="login-password" />
                    </div>
                    
                    <Button type="submit" id="login-submit" className="btn btn-primary">Submit</Button>
                </Form>
            </div>
        </Container>
    )
}

export default LoginForm;