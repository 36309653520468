import HallPage from "./components/routes/HallPage";
import ReviewFormPage from "./components/routes/ReviewFormPage";
import { Route, Routes } from "react-router-dom";
import HomePage from "./components/routes/HomePage";
import ErrorPage from "./components/routes/ErrorPage";
import SignupPage from "./components/routes/SignupPage";
import LoginPage from "./components/routes/LoginPage";
import CallbackPage from "./components/routes/CallbackPage";
import Profile from "./components/routes/Profile";
import { useAuth0 } from "@auth0/auth0-react";

function App() {
  const { isLoading } = useAuth0();

  if (isLoading) {
    // TODO add a page loader component
  }
  return (
    <Routes>
      <Route path="/" element={<HomePage />} errorElement={<ErrorPage />} />
      <Route path="/hall/:id/" element={<HallPage view="reviews" />} />
      <Route path="/hall/:id/reviews" element={<HallPage view="reviews" />} />
      <Route
        path="/hall/:id/learn-more"
        element={<HallPage view="learn-more" />}
      />
      <Route path="/leave-review" element={<ReviewFormPage />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/callback" element={<CallbackPage />} />
      <Route path="/profile" element={<Profile />} />
    </Routes>
  );
}

export default App;
