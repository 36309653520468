import {
  faBookOpenReader,
  faHouseChimney,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AdvancedMarker,
  APIProvider,
  Map,
  MapCameraChangedEvent,
  Pin,
} from "@vis.gl/react-google-maps";

type HallMapProps = {
  title: string;
  latitude: number;
  longitude: number;
};

type Poi = { key: string; location: google.maps.LatLngLiteral };

type PoiMarkersProps = {
  hallPoi: Poi;
  universityPoi: Poi;
};

const universityCampusCoordinates = {
  lat: -45.864541446015714,
  lng: 170.51443340958724,
};

const HallMap = ({ title, latitude, longitude }: HallMapProps) => {
  const GOOGLE_MAPS_API_KEY = "AIzaSyBlgPfFR2-UV2V59VCKnmmmOo6aH56HNKI";
  // Why is this not working?
  //   const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  //   if (!GOOGLE_MAPS_API_KEY) {
  //     return <p className="mt-3">Error: could not load map</p>;
  //   }

  const PoiMarkers = ({ hallPoi, universityPoi }: PoiMarkersProps) => {
    return (
      <>
        <AdvancedMarker
          title={title}
          key={hallPoi.key}
          position={hallPoi.location}
        >
          <Pin background="#27374d" scale={1.2} borderColor="white">
            <FontAwesomeIcon
              color="white"
              fontSize="1rem"
              icon={faHouseChimney}
            />
          </Pin>
        </AdvancedMarker>
        <AdvancedMarker
          title="University of Otago Campus"
          key={universityPoi.key}
          position={universityPoi.location}
        >
          <Pin background="#27374d" borderColor="white">
            <FontAwesomeIcon
              color="white"
              fontSize="1rem"
              icon={faBookOpenReader}
            />
          </Pin>
        </AdvancedMarker>
      </>
    );
  };

  return (
    <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
      <div className="mt-2 mb-5" style={{ width: "100%", height: "700px" }}>
        <Map
          defaultZoom={14}
          defaultCenter={{ lat: latitude, lng: longitude }}
          mapId="346a9cd22307d024"
          onCameraChanged={(ev: MapCameraChangedEvent) =>
            console.log(
              "camera changed:",
              ev.detail.center,
              "zoom:",
              ev.detail.zoom
            )
          }
        >
          <PoiMarkers
            hallPoi={{
              key: "hall",
              location: { lat: latitude, lng: longitude },
            }}
            universityPoi={{
              key: "universityCampus",
              location: {
                lat: universityCampusCoordinates.lat,
                lng: universityCampusCoordinates.lng,
              },
            }}
          />
        </Map>
      </div>
    </APIProvider>
  );
};

export default HallMap;
