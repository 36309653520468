import React from "react";
import NavBar from '../NavBar';

const CallbackPage = () => {
    return (
        <div className="page-layout">
            <NavBar />
        </div>
    )
}

export default CallbackPage;