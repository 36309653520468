import { useRouteError, isRouteErrorResponse } from "react-router-dom";
import NavBar from "../NavBar";

const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);
  let errorMessage = "Unknown error";

  if (isRouteErrorResponse(error)) {
    // error is type `ErrorResponse`
    errorMessage = error.error?.message || error.statusText;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === 'string') {
    errorMessage = error;
  } 

  return (
    <>
      <NavBar />
      <div id="error-page">
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p>
          <i>{isRouteErrorResponse(error) && errorMessage}</i>
        </p>
      </div>
    </>
  );
}

export default ErrorPage;