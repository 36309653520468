import { useEffect } from "react";
import { useState } from "react";
import { Row, Dropdown, Col, Pagination } from "react-bootstrap";
import ReviewGrid from "./ReviewGrid";

type Review = {
  id: string;
  hallId: string;
  userId: string;
  rating: number;
  title?: string;
  reviewText: string;
  createdDate: string;
  author: string;
  lastEdited: string;
};

type ReviewGridProps = {
  reviews: Review[];
  sortByHandler: (sortBy: string | undefined) => void;
};

const ReviewSection = ({ reviews, sortByHandler }: ReviewGridProps) => {
  const pageSize = 8;
  const paginationLimit = 10;
  const pages = Math.ceil(reviews?.length / pageSize);
  const [page, setPage] = useState<number>(1);
  const [reviewsInPage, setReviewsInPage] = useState<Review[]>(
    reviews.slice(0, page * pageSize)
  );
  const [sortBy, setSortBy] = useState<string>("LATEST");
  useEffect(() => {
    sortByHandler(sortBy);
  }, [sortBy, sortByHandler]);

  useEffect(() => {
    setPage(1);
  }, [reviews]);

  useEffect(() => {
    setReviewsInPage(reviews.slice((page - 1) * pageSize, page * pageSize));
  }, [page, reviews]);

  const regularPaginationCounter = Array.from(Array(pages).keys()).map((n) => (
    <Pagination.Item
      key={n}
      disabled={page === n + 1}
      onClick={() => setPage(n + 1)}
    >
      {n + 1}
    </Pagination.Item>
  ));

  const buildUpPageMatrix = () => {
    const pageMatrix = [];
    const setsOfPages = Math.ceil(pages / paginationLimit);

    for (let i = 0; i < setsOfPages; i++) {
      var row = [];
      for (let j = 0; j < paginationLimit; j++) {
        if (i * paginationLimit + j >= pages) {
          break;
        }
        row[j] = i * paginationLimit + j + 1;
      }
      pageMatrix[i] = row;
    }

    return pageMatrix;
  };

  // TODO: big refactor needed here - put testing in place first
  const longPaginationCounter = () => {
    // building up an array of arrays that contain page sets
    const pageMatrix = buildUpPageMatrix();

    const paginationElements = [];
    const currentSet = Math.ceil(page / paginationLimit);
    const pagesInSet = pageMatrix[currentSet - 1];

    const isCurrentSetFirstSet = currentSet === 1;
    const isCurrentSetLastSet = currentSet === pageMatrix.length;
    const isCurrentSetMiddleSet = !isCurrentSetFirstSet && !isCurrentSetLastSet;

    if (isCurrentSetLastSet || isCurrentSetMiddleSet) {
      paginationElements.push(
        <Pagination.Ellipsis onClick={() => setPage(pagesInSet[0] - 1)} />
      );
    }

    paginationElements.push(
      ...pagesInSet.map((pageNum) => (
        <Pagination.Item
          key={pageNum}
          disabled={page === pageNum}
          onClick={() => setPage(pageNum)}
        >
          {pageNum}
        </Pagination.Item>
      ))
    );

    if (isCurrentSetFirstSet || isCurrentSetMiddleSet) {
      paginationElements.push(
        <Pagination.Ellipsis
          onClick={() => setPage(pagesInSet[pagesInSet.length - 1] + 1)}
        />
      );
    }

    return paginationElements;
  };

  const pagination = (
    <Pagination className="review-pagination mt-5">
      <Pagination.Prev
        disabled={page === 1}
        onClick={() => setPage((currentPage) => currentPage - 1)}
      />

      {pages <= paginationLimit
        ? regularPaginationCounter
        : longPaginationCounter()}

      <Pagination.Next
        disabled={page === pages}
        onClick={() => setPage((currentPage) => currentPage + 1)}
      />
    </Pagination>
  );

  const sortByDict = (sortByKey: string) => {
    if (sortByKey === "LATEST") return "Latest";
    if (sortByKey === "HIGHEST_RATED") return "Highest rated";
    if (sortByKey === "LOWEST_RATED") return "Lowest rated";
  };

  return (
    <div className="hall-reviews-container p-4 mb-5">
      <Row className="mb-3">
        <Col xs={12}>
          {reviews && reviews?.length > 0 && (
            <>
              <Dropdown data-bs-theme="dark" className="sort-reviews-dropdown">
                <Dropdown.Toggle>
                  Sort reviews{" "}
                  {sortBy && (
                    <small className="text-muted">({sortByDict(sortBy)})</small>
                  )}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setSortBy("LATEST");
                    }}
                    active={sortBy === "LATEST"}
                  >
                    Latest
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setSortBy("HIGHEST_RATED")}
                    active={sortBy === "HIGHEST_RATED"}
                  >
                    Highest rated
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setSortBy("LOWEST_RATED")}
                    active={sortBy === "LOWEST_RATED"}
                  >
                    Lowest rated
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}
        </Col>
      </Row>

      <ReviewGrid reviews={reviewsInPage} />
      {reviews.length > pageSize && pagination}
    </div>
  );
};

export default ReviewSection;
