import { Toast } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";

export type ToastResultOptions = "Success" | "Failure";

type ToastProps = ToastMessage & {
  setToastMessage: React.Dispatch<
    React.SetStateAction<ToastMessage | undefined>
  >;
};

export type ToastMessage = {
  message: string;
  result: ToastResultOptions;
};

const CustomToast = ({ message, result, setToastMessage }: ToastProps) => {
  return (
    <Toast
      className="account-toast"
      onClose={() => setToastMessage(undefined)}
      show={message !== undefined}
      delay={5000}
      bg={result === "Success" ? "success" : "danger"}
      color="white"
      autohide
    >
      <Toast.Body>
        {result === "Failure" ? (
          <FontAwesomeIcon className="me-1" icon={faCircleExclamation} />
        ) : (
          <FontAwesomeIcon className="me-1" icon={faCircleCheck} />
        )}
        {message}
      </Toast.Body>
    </Toast>
  );
};

export default CustomToast;
