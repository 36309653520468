import { Card, Col, Row } from "react-bootstrap";
import { Hall } from "./NavBar";
import { formatCostStr } from "./HallInfo";
import { HallSortByOptions } from "./routes/HomePage";

type HallListViewProps = {
  halls: Hall[];
  hallImgBaseURL: string;
  sortHallsBy: HallSortByOptions;
};

// TODO: build a dropdown which determines which fields to show - we also want to show number of beds
// TODO: highlight the attribute which is being sorted by, allow the user to clear the sort
const HallListView = ({
  halls,
  hallImgBaseURL,
  sortHallsBy,
}: HallListViewProps) => {
  const HallAttributes = (hall: Hall) => {
    var attributes = [
      ["averageRating", hall.averageRating],
      ["cost", hall.cost],
      ["campusDistance", hall.campusDistance],
      // ["population", hall.population],
    ]
      .filter((attr) => attr[1] != null)
      .map((attr) => attr[0]);

    const elements: JSX.Element[] = [];

    for (let i = 0; i < attributes.length; i++) {
      const elementClasses = ["col-4"];
      if (i === attributes.length - 1 && attributes.length === 3)
        elementClasses.push("text-end");
      if (i !== attributes.length - 1) elementClasses.push("border-end");
      if (i === 1) elementClasses.push("text-center");
      if (i === 0) elementClasses.push("text-start");

      elements.push(
        <div className={elementClasses.join(" ")}>
          {attributes[i] === "averageRating" && (
            <>{Math.round(hall.averageRating * 100) / 100}/10</>
          )}
          {attributes[i] === "cost" && <>{formatCostStr(hall.cost)}</>}
          {attributes[i] === "campusDistance" && (
            <>{hall.campusDistance} mins</>
          )}
          {/* {attributes[i] === "population" && <>{hall.population} beds</>} */}
        </div>
      );
    }

    return <div className="mb-0 row">{elements}</div>;
  };

  return (
    <Row>
      {halls.map((hall) => (
        <Col className="mb-4" xs={12} md={6} lg={4} xl={3}>
          <a href={"/hall/" + hall.id} style={{ textDecoration: "none" }}>
            <Card style={{ borderRadius: 0, border: 0 }}>
              <div className="card-img-container">
                <Card.Img
                  style={{
                    borderRadius: 0,
                    objectFit: "cover",
                    height: "200px",
                  }}
                  src={
                    hall.imgSrc
                      ? `${hallImgBaseURL}${hall.imgSrc}`
                      : "/assets/images/clocktower.jpeg"
                  }
                />
              </div>
              <Card.Body className="hall-card-body">
                <Card.Title style={{ textDecoration: "underline" }}>
                  {hall.name}
                </Card.Title>
                {HallAttributes(hall)}
              </Card.Body>
            </Card>
          </a>
        </Col>
      ))}
    </Row>
  );
};

export default HallListView;
