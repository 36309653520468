import { useAuth0 } from "@auth0/auth0-react";
import { Nav } from "react-bootstrap";

const LogoutButton = () => {
    const { logout } = useAuth0();

    const handleLogout = async () => {
        await logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
        })
    }

    return (
        <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
    )
}

export default LogoutButton;