import { useRef, useState } from "react";
import { Alert, Button, CloseButton, Form, Modal } from "react-bootstrap";
import { UserReview } from "./routes/Profile";

type UpdateReviewModalProps = {
  show: boolean;
  handleClose: () => void;
  review: UserReview | undefined;
  submit: (
    title: string | undefined,
    reviewText: string | undefined,
    rating: string | undefined
  ) => void;
};

const UpdateReviewModal = ({
  show,
  handleClose,
  review,
  submit,
}: UpdateReviewModalProps) => {
  const reviewTextRef = useRef<HTMLTextAreaElement>(null);
  const ratingSelectRef = useRef<HTMLSelectElement>(null);
  const reviewTitleRef = useRef<HTMLInputElement>(null);

  const [error, setError] = useState<string>("");
  const [showError, setShowError] = useState<boolean>(false);

  const ratingOptions = Array.from({ length: 10 }, (_, i) => i + 1);

  const onSubmit = () => {
    setError("");
    setShowError(false);

    const rating = ratingSelectRef.current?.value!;
    const reviewText = reviewTextRef.current?.value;
    const reviewTitle = reviewTitleRef.current?.value;

    if (parseInt(rating) === review?.rating && !reviewText && !reviewTitle) {
      setError("You must update at least one field.");
      setShowError(true);
      return;
    }

    submit(reviewTitle, reviewText, rating);
  };

  return (
    <Modal
      show={show}
      style={{ borderRadius: 0 }}
      className="update-review-modal profile-page-modal"
    >
      <Modal.Body className="m-2" style={{ borderRadius: 0 }}>
        <CloseButton
          onClick={() => handleClose()}
          className="close-modal-btn"
        />
        <h3 style={{ display: "inline-block" }}>Update review</h3>
        <Form className="hall-review-edit-form">
          <div className="mb-3">
            <label className="form-label">Title</label>
            <input
              type="text"
              ref={reviewTitleRef}
              className="form-control"
              placeholder={review?.title}
              id="review-title"
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Review</label>
            <textarea
              className="form-control"
              ref={reviewTextRef}
              placeholder={review?.reviewText}
              id="review-body"
            />
            <div id="emailHelp" className="form-text">
              Tell us about your hall experience.
            </div>
          </div>
          <div className="mb-3">
            <label>Rating</label>
            <Form.Select
              className="form-select"
              id="review-rating"
              ref={ratingSelectRef}
              defaultValue={review?.rating}
              aria-label="Default select example"
            >
              {ratingOptions.map((ratingNum) => (
                <option key={ratingNum} value={ratingNum}>
                  {ratingNum}
                </option>
              ))}
            </Form.Select>
          </div>
        </Form>
        {showError && <Alert variant="danger">{error}</Alert>}
        <Button variant="success" onClick={() => onSubmit()}>
          Submit
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateReviewModal;
