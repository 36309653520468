import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Row } from "react-bootstrap";
import ReviewCard from "./ReviewCard";
import { useEffect, useState } from "react";
import ReviewCardModal, { ReviewCardModalData } from "./ReviewCardModal";

type ReviewData = {
  id: string;
  hallId: string;
  userId: string;
  rating: number;
  title?: string;
  reviewText: string;
  createdDate: string;
  author: string;
  lastEdited: string;
};

type ReviewGridProps = {
  reviews: ReviewData[];
};

const ReviewGrid = ({ reviews }: ReviewGridProps) => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [maxAuthorLengthCutoff, setMaxAuthorLengthCutoff] = useState(25);
  const [show, setShow] = useState(false);
  const [clickedReview, setClickedReview] = useState<
    ReviewCardModalData | undefined
  >();

  const onClickHandler = (review: ReviewCardModalData) => {
    setShow(true);
    setClickedReview(review);
    return;
  };

  const closeHandler = () => {
    setShow(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    var maxAuthorCutoff = 25;
    if (screenSize.width > 992 && screenSize.width < 1200) maxAuthorCutoff = 14;
    setMaxAuthorLengthCutoff(maxAuthorCutoff);
  }, [screenSize.width]);

  return (
    <>
      <Row>
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 576: 1, 768: 2, 992: 3, 1400: 4 }}
        >
          <Masonry gutter="15px">
            {reviews.map((review) => (
              <ReviewCard
                key={review.id}
                id={review.id}
                title={review.title}
                rating={review.rating}
                review={review.reviewText}
                date={review.createdDate}
                author={review.author}
                maxAuthorLength={maxAuthorLengthCutoff}
                onClickHandler={onClickHandler}
              />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </Row>

      <ReviewCardModal
        show={show}
        handleClose={closeHandler}
        review={clickedReview}
      />
    </>
  );
};

export default ReviewGrid;
