import { useEffect, useState } from "react";

const useFetch = <T>(
  url: string
): [
  T | undefined,
  any,
  boolean,
  React.Dispatch<React.SetStateAction<T | undefined>>
] => {
  // pass in body param as well
  // if body exists
  const [data, setData] = useState<T>();
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchLogic = async () => {
      try {
        const response = await fetch(url);
        const json = await response.json();
        setData(json);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchLogic();
  }, [url]);

  return [data, error, loading, setData];
};

export default useFetch;
