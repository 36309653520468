import React from 'react';
import NavBar from '../NavBar';
import SignupForm from '../SignupForm';

const SignupPage = () => {
    return (
        <>
            <NavBar />

            <SignupForm />
        </>
    );
}

export default SignupPage;