import { useAuth0 } from "@auth0/auth0-react";
import { Nav } from "react-bootstrap";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/",
      },
    });
  };

  return <Nav.Link onClick={handleLogin}>Login</Nav.Link>;
};

export default LoginButton;
