import { useEffect, useRef, useState } from "react";
import { Button, Form, Container, Alert } from "react-bootstrap";
import "../forms.css";
import useFetch from "../hooks/useFetch";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { ToastMessage } from "./CustomToast";

type Hall = {
  id: string;
  name: string;
  bio: string;
  population: number;
  campusDistance: number;
  cost: number;
};

type ReviewFormProps = {
  setToastMessage: ({ message, result }: ToastMessage) => void;
};

const ReviewForm = ({ setToastMessage }: ReviewFormProps) => {
  const navigate = useNavigate();

  const reviewTextRef = useRef<HTMLTextAreaElement>(null);
  const hallSelectRef = useRef<HTMLSelectElement>(null);
  const ratingSelectRef = useRef<HTMLSelectElement>(null);
  const reviewTitleRef = useRef<HTMLInputElement>(null);

  const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;
  const { user, getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState<string>("");
  const [showError, setShowError] = useState<boolean>(false);
  const ratingOptions = Array.from({ length: 10 }, (_, i) => i + 1);

  const [hallsData] = useFetch<Hall[]>(`${API_BASE_URL}/hall/GetHalls`);

  useEffect(() => {
    if (error === "") {
      setShowError(false);
    } else {
      setShowError(true);
    }
  }, [error]);

  const validateReviewForm = () => {
    setError("");

    if (!user) {
      console.error("Error: user object does not exist");
      return false;
    }
    if (hallSelectRef.current?.value === "--") {
      setError("A review requires a hall.");
      return false;
    }
    if (
      reviewTextRef.current?.value === "" ||
      reviewTextRef.current?.value === undefined
    ) {
      setError("You cannot leave the review field empty.");
      return false;
    }
    if (ratingSelectRef.current?.value === "--") {
      setError("A review requires a rating.");
      return false;
    }

    const ratingPosted = parseInt(ratingSelectRef.current!.value);
    if (ratingPosted < 1 || ratingPosted > 10) {
      setError("A rating must be between 1 and 10.");
      console.error("Error: a rating must be between 1 and 10.");
      return false;
    }

    return true;
  };

  const postReview = async () => {
    if (!validateReviewForm()) {
      return;
    }

    if (!user) {
      return;
    }
    const userEmail = user.email;
    const username = user.nickname;
    const userId = user.sub;

    const userData = {
      Id: userId,
      Username: username,
      Email: userEmail,
    };

    const reviewRequestBody = {
      HallId: hallSelectRef.current?.value,
      Rating: ratingSelectRef.current
        ? parseInt(ratingSelectRef.current?.value)
        : null,
      ReviewText: reviewTextRef.current?.value,
      ReviewTitle: reviewTitleRef.current?.value,
      User: userData,
    };

    var postReviewResponse;

    // TODO: use the useFetch hook to do this POST
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: "htttp://hall-review.test.com",
        },
      });
      postReviewResponse = await fetch(`${API_BASE_URL}/reviews`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(reviewRequestBody),
      });
    } catch (error) {
      console.error("Error:", error);
      setToastMessage({
        message: "There was a problem submitting this review.",
        result: "Failure",
      });
      return;
    }

    if (postReviewResponse.ok) {
      setToastMessage({
        message: "Successfully created review",
        result: "Success",
      });
      const hallId = hallSelectRef.current!.value;
      setTimeout(() => navigate(`/hall/${hallId}`), 2000);
    } else {
      setToastMessage({
        message: "There was a problem submitting this review.",
        result: "Failure",
      });
      console.error("Error:", error);
    }
  };

  return (
    <Container>
      <h2 className="text-center mt-5 mb-4">Leave a Review</h2>
      <div className="form-container pt-2 pb-2">
        <Form className="hall-review-form">
          <div className="mb-3">
            <label>Hall</label>
            <Form.Select
              className="form-select"
              id="review-rating"
              ref={hallSelectRef}
              aria-label="Default select example"
            >
              <option defaultValue="--">--</option>
              {hallsData?.map((hall) => (
                <option key={hall.id} value={hall.id}>
                  {hall.name}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className="mb-3">
            <label className="form-label">Title</label>
            <input
              type="text"
              ref={reviewTitleRef}
              className="form-control"
              id="review-title"
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Review</label>
            <textarea
              className="form-control"
              ref={reviewTextRef}
              id="review-body"
            />
            <div id="emailHelp" className="form-text">
              Tell us about your hall experience.
            </div>
          </div>
          <div className="mb-3">
            <label>Rating</label>
            <Form.Select
              className="form-select"
              id="review-rating"
              ref={ratingSelectRef}
              aria-label="Default select example"
            >
              <option defaultValue="--">--</option>
              {ratingOptions.map((ratingNum) => (
                <option key={ratingNum} value={ratingNum}>
                  {ratingNum}
                </option>
              ))}
            </Form.Select>
          </div>
          {showError && <Alert variant="danger">{error}</Alert>}
          <Button
            onClick={postReview}
            id="review-submit"
            className="btn btn-primary"
          >
            Submit
          </Button>
        </Form>
      </div>
    </Container>
  );
};

export default ReviewForm;
