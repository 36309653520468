import {
  faBookOpenReader,
  faHouseChimney,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AdvancedMarker,
  APIProvider,
  Map,
  MapCameraChangedEvent,
  Pin,
} from "@vis.gl/react-google-maps";

export type HallMapProps = {
  hallCoordinates: HallMapItem[];
  homePageRef: React.RefObject<HTMLDivElement>;
};

export type HallMapItem = {
  title: string;
  coordinates: Coordinate;
};

type Coordinate = {
  latitude: number;
  longitude: number;
};

type Marker = { key: string; location: google.maps.LatLngLiteral };

type MarkersProps = {
  hallMarkers: Marker[];
  universityMarker: Marker;
};

const universityCampusCoordinates = {
  lat: -45.864541446015714,
  lng: 170.51443340958724,
};

const HomePageMap = ({ hallCoordinates, homePageRef }: HallMapProps) => {
  const GOOGLE_MAPS_API_KEY = "AIzaSyBlgPfFR2-UV2V59VCKnmmmOo6aH56HNKI";

  const Markers = ({ hallMarkers, universityMarker }: MarkersProps) => {
    return (
      <>
        {hallMarkers.map((hallMarker) => (
          <AdvancedMarker
            title={hallMarker.key}
            key={hallMarker.key}
            position={hallMarker.location}
          >
            <Pin background="#27374d" scale={1} borderColor="white">
              <FontAwesomeIcon
                color="white"
                fontSize="1rem"
                icon={faHouseChimney}
              />
            </Pin>
          </AdvancedMarker>
        ))}

        <AdvancedMarker
          title="University of Otago Campus"
          key={universityMarker.key}
          position={universityMarker.location}
        >
          <Pin background="#27374d" borderColor="white">
            <FontAwesomeIcon
              color="white"
              fontSize="1rem"
              icon={faBookOpenReader}
            />
          </Pin>
        </AdvancedMarker>
      </>
    );
  };

  return (
    <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
      <div ref={homePageRef} className="homepage-map">
        {/* What should the default be */}
        <Map
          defaultZoom={14}
          defaultCenter={{
            lat: universityCampusCoordinates.lat,
            lng: universityCampusCoordinates.lng,
          }}
          mapId="346a9cd22307d024"
          onCameraChanged={(ev: MapCameraChangedEvent) =>
            console.log(
              "camera changed:",
              ev.detail.center,
              "zoom:",
              ev.detail.zoom
            )
          }
        >
          <Markers
            hallMarkers={hallCoordinates.map((hall) => {
              return {
                key: hall.title,
                location: {
                  lat: hall.coordinates.latitude,
                  lng: hall.coordinates.longitude,
                },
              };
            })}
            universityMarker={{
              key: "universityCampus",
              location: {
                lat: universityCampusCoordinates.lat,
                lng: universityCampusCoordinates.lng,
              },
            }}
          />
        </Map>
      </div>
    </APIProvider>
  );
};

export default HomePageMap;
