import { Container, Dropdown, Row } from "react-bootstrap";
import NavBar, { Hall } from "../NavBar";
import useFetch from "../../hooks/useFetch";
import HomePageMap, { HallMapItem } from "../HomePageMap";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import HallListView from "../HallListView";

export type HallSortByOptions =
  | "NameAscending"
  | "NameDescending"
  | "AverageRatingAscending"
  | "AverageRatingDescending"
  | "CostAscending"
  | "CostDescending"
  | "CampusDistanceAscending"
  | "CampusDistanceDescending"
  | undefined;

const HomePage = () => {
  const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;
  console.log(API_BASE_URL);
  const hallImgBaseURL = "https://hallreview-photos.s3.amazonaws.com/";
  const [hallCoordinates, setHallCoordinates] = useState<HallMapItem[]>([]);
  const HomePageMapRef = useRef<HTMLDivElement>(null);
  const [showMap, setShowMap] = useState(true);
  const [sortHallsBy, setSortHallsBy] = useState<HallSortByOptions>();

  const homePageRef = useRef<HTMLDivElement>(null);
  const closeMapRef = useRef<HTMLDivElement>(null);
  const firstUpdate = useRef(true);

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (homePageRef.current == null) return;
    if (closeMapRef.current == null) return;

    if (!showMap) {
      homePageRef.current.style.height = "0";
      closeMapRef.current.style.display = "none";
    } else {
      homePageRef.current.style.height = "500px";
      setTimeout(() => (closeMapRef!.current!.style.display = ""), 500);
    }
  }, [showMap]);

  const [halls] = useFetch<Hall[]>(`${API_BASE_URL}/hall/GetHalls`);
  const [sortedHalls, setSortedHalls] = useState<Hall[]>(halls ?? []);

  useEffect(() => {
    if (halls === undefined) return;
    switch (sortHallsBy) {
      case "NameAscending":
        setSortedHalls(
          halls.slice().sort((a, b) => a.name.localeCompare(b.name))
        );
        break;
      case "NameDescending":
        setSortedHalls(
          halls.slice().sort((a, b) => b.name.localeCompare(a.name))
        );
        break;
      case "CostAscending":
        setSortedHalls(halls.slice().sort((a, b) => a.cost - b.cost));
        break;
      case "CostDescending":
        setSortedHalls(halls.slice().sort((a, b) => b.cost - a.cost));
        break;
      case "CampusDistanceAscending":
        setSortedHalls(
          halls.slice().sort((a, b) => a.campusDistance - b.campusDistance)
        );
        break;
      case "CampusDistanceDescending":
        setSortedHalls(
          halls.slice().sort((a, b) => b.campusDistance - a.campusDistance)
        );
        break;
      case "AverageRatingAscending":
        setSortedHalls(
          halls.slice().sort((a, b) => a.averageRating - b.averageRating)
        );
        break;
      case "AverageRatingDescending":
        setSortedHalls(
          halls.slice().sort((a, b) => b.averageRating - a.averageRating)
        );
        break;
      default:
        setSortedHalls(
          halls.slice().sort((a, b) => a.name.localeCompare(b.name))
        );
    }
  }, [halls, sortHallsBy, setSortedHalls]);

  useEffect(() => {
    if (halls === undefined) {
      setHallCoordinates([]);
      return;
    }

    var hallsWithCoordinates = halls.filter(
      (hall) => hall.latitude !== null && hall.longitude != null
    );

    var hallItems = hallsWithCoordinates.map((hall) => {
      return {
        title: hall.name,
        coordinates: {
          latitude: hall.latitude,
          longitude: hall.longitude,
        },
      };
    });
    setHallCoordinates(hallItems);
  }, [halls]);

  return (
    <>
      <NavBar showMap={showMap} setShowMap={setShowMap} />
      <div
        className="close-map-icon"
        ref={closeMapRef}
        onClick={() => setShowMap(false)}
      >
        <FontAwesomeIcon color={"white"} icon={faClose} />
      </div>
      <div className="mt-0 mb-6" ref={HomePageMapRef}>
        <HomePageMap
          homePageRef={homePageRef}
          hallCoordinates={hallCoordinates}
        />
        <div className="ribbon-below-map"></div>
      </div>

      <Container className="mt-5 mb-3 homepage-grid">
        <Row className="mb-3">
          <Dropdown data-bs-theme="dark" className="sort-reviews-dropdown">
            <Dropdown.Toggle>Sort halls</Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setSortHallsBy("NameAscending")}>
                Name (A-Z)
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setSortHallsBy("NameDescending")}>
                Name (Z-A)
              </Dropdown.Item>
              {/*
                <Dropdown.Item>Resident Population (high to low)</Dropdown.Item>
                <Dropdown.Item>Resident Population (low to high)</Dropdown.Item>
                <Dropdown.Divider />
                */}
              <Dropdown.Divider />
              <Dropdown.Item onClick={() => setSortHallsBy("CostDescending")}>
                Cost (high to low)
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setSortHallsBy("CostAscending")}>
                Cost (low to high)
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                onClick={() => setSortHallsBy("CampusDistanceAscending")}
              >
                Distance from Campus (low to high, minutes from campus)
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setSortHallsBy("CampusDistanceDescending")}
              >
                Distance from Campus (high to low, minutes from campus)
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                onClick={() => setSortHallsBy("AverageRatingDescending")}
              >
                Average Rating (high to low)
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setSortHallsBy("AverageRatingAscending")}
              >
                Average Rating (low to high)
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Row>

        {halls && (
          <HallListView
            halls={sortedHalls}
            hallImgBaseURL={hallImgBaseURL}
            sortHallsBy={sortHallsBy}
          />
        )}
      </Container>
    </>
  );
};

export default HomePage;
