import { faCalendar, faStar, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CloseButton, Modal } from "react-bootstrap";
import { formatDateStr } from "../helpers/DateHelper";

export type ReviewCardModalData = {
  id: string;
  title?: string;
  rating: number;
  author: string;
  reviewText: string;
  createdDate: string;
};

type ReviewCardModalProps = {
  show: boolean;
  handleClose: () => void;
  review?: ReviewCardModalData;
};

const ReviewCardModal = ({
  show,
  handleClose,
  review,
}: ReviewCardModalProps) => {
  return (
    <Modal
      show={show && review !== undefined}
      onHide={handleClose}
      style={{ borderRadius: 0 }}
      className="review-card-modal"
    >
      <Modal.Body className="m-2" style={{ borderRadius: 0 }}>
        <CloseButton
          className="close-modal-btn"
          onClick={() => handleClose()}
        />
        {review?.title && (
          <h3 style={{ display: "inline-block" }}>{review?.title}</h3>
        )}
        <div className="mb-3">
          <FontAwesomeIcon
            style={{
              color: "gold",
              marginRight: "5px",
            }}
            icon={faStar}
          />
          <span style={{ fontWeight: 400, fontSize: "1.3rem" }}>
            {review?.rating}
          </span>
          <span>/10</span>
        </div>

        <p className="mb-4">{review?.reviewText}</p>
        <span className="text-muted">
          <FontAwesomeIcon
            style={{ fontSize: "0.8rem", marginRight: "8px" }}
            icon={faUser}
          />
          {review?.author}
        </span>
        <br />
        <span className="text-muted">
          <FontAwesomeIcon
            style={{ fontSize: "0.8rem", marginRight: "8px" }}
            icon={faCalendar}
          />
          {formatDateStr(review?.createdDate!)}
        </span>
      </Modal.Body>
    </Modal>
  );
};

export default ReviewCardModal;
