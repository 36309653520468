import React, { useState } from "react";
import NavBar from "../NavBar";
import ReviewForm from "../ReviewForm";
import CustomToast, { ToastMessage } from "../CustomToast";

const ReviewFormPage = () => {
  const [toastMessage, setToastMessage] = useState<ToastMessage | undefined>(
    undefined
  );

  return (
    <>
      <NavBar />

      <ReviewForm setToastMessage={setToastMessage} />

      {toastMessage && (
        <CustomToast
          setToastMessage={setToastMessage}
          message={toastMessage.message}
          result={toastMessage.result}
        />
      )}
    </>
  );
};

export default ReviewFormPage;
