import { useAuth0 } from "@auth0/auth0-react";
import { Nav } from "react-bootstrap";

const SignupButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleSignup = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/successful-sign-up",
      },
      authorizationParams: {
        screen_hint: "signup",
      },
    });
  };

  return <Nav.Link onClick={handleSignup}>Signup</Nav.Link>;
};

export default SignupButton;
