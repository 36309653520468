import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { Badge, Button, Container } from "react-bootstrap";
import NavBar from "../NavBar";
import DeleteAccountModal from "../DeleteAccountModal";
import useFetch from "../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import UpdateReviewModal from "../UpdateReviewModal";
import DeleteReviewModal from "../DeleteReviewModal";
import CustomToast, { ToastMessage } from "../CustomToast";

export type UserData = {
  userId: string;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  review: UserReview;
};

export type UserReview = {
  id: string;
  title: string;
  rating: number;
  reviewText: string;
  createdDate: string;
  lastEdited: string;
  hallId: string;
};

const Profile = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;
  const navigate = useNavigate();

  const [userData] = useFetch<UserData>(`${API_BASE_URL}/user/${user?.sub}`);

  const [showDeleteAccountModal, setShowDeleteAccountModal] =
    useState<boolean>(false);
  const [showUpdateReviewModal, setShowUpdateReviewModal] =
    useState<boolean>(false);
  const [showDeleteReviewModal, setShowDeleteReviewModal] =
    useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<ToastMessage | undefined>(
    undefined
  );

  const visitReview = () => {
    const hallId = userData?.review?.hallId;
    navigate("/hall/" + hallId);
  };

  const updateReview = async (
    title: string | undefined,
    reviewText: string | undefined,
    rating: string | undefined
  ) => {
    var queryString = `${API_BASE_URL}/reviews/update/${userData?.review?.id}?`;
    var queryParams = new URLSearchParams();
    if (title) {
      queryParams.append("title", title);
    }
    if (reviewText) {
      queryParams.append("reviewText", reviewText);
    }
    if (rating) {
      queryParams.append("rating", rating);
    }
    queryString += queryParams.toString();

    const response = await fetch(queryString, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      setToastMessage({
        message: "Review successfully updated.",
        result: "Success",
      });
      setTimeout(() => visitReview(), 3000);
    } else {
      setToastMessage({
        message: "Something went wrong. Unable to update review.",
        result: "Failure",
      });
    }
  };

  const deleteAccount = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: "htttp://hall-review.test.com",
      },
    });

    const response = await fetch(`${API_BASE_URL}/user/${user?.sub!}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      setToastMessage({
        message: "Account successfully deleted.",
        result: "Success",
      });
    } else {
      setToastMessage({
        message: "Something went wrong. Unable to delete account.",
        result: "Failure",
      });
    }

    await new Promise((res) => setTimeout(res, 3000));
    window.location.reload();
  };

  const modals = (
    <>
      <DeleteAccountModal
        show={showDeleteAccountModal}
        handleClose={() => setShowDeleteAccountModal(false)}
        deleteAccount={deleteAccount}
      />
      <DeleteReviewModal
        show={showDeleteReviewModal}
        reviewId={userData?.review?.id}
        handleClose={() => setShowDeleteReviewModal(false)}
        setToastMessage={setToastMessage}
      />
      <UpdateReviewModal
        show={showUpdateReviewModal}
        handleClose={() => setShowUpdateReviewModal(false)}
        review={userData?.review!}
        submit={updateReview}
      />
    </>
  );

  return (
    <>
      <NavBar />

      <Container>
        <h3 className="pt-4">Manage profile</h3>
        <p>Manage your profile/account settings</p>
        <hr />
        {userData?.review && (
          <>
            <div className="review-settings-section row">
              <div className="left-heading col">
                <h6>Your review</h6>
              </div>
              <div className="settings-actions col-8 pt-4">
                <div className="row mb-1">
                  <div className="col-7"> </div>
                  <div className="col">
                    <Button variant="primary" onClick={() => visitReview()}>
                      Visit review
                    </Button>
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col-7"></div>
                  <div className="col">
                    <Button
                      variant="primary"
                      onClick={() => setShowUpdateReviewModal(true)}
                    >
                      Edit review
                    </Button>
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col-7 min-height-100">
                    <p>
                      <Badge bg="danger">!</Badge> You cannot restore a deleted
                      review
                    </p>
                  </div>
                  <div className="col">
                    <Button
                      variant="danger"
                      onClick={() => setShowDeleteReviewModal(true)}
                    >
                      Delete review
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </>
        )}

        <div className="account-settings-section row">
          <div className="left-heading col-sm">
            <h6>Your account</h6>
          </div>
          <div className="settings-actions col-8 pt-4">
            <div className="row mb-1">
              <div className="col-7">
                <p>
                  <Badge bg="danger">!</Badge> Deleting your account will also
                  delete your review
                </p>
              </div>
              <div className="col">
                <Button
                  variant="danger"
                  onClick={() => setShowDeleteAccountModal(true)}
                >
                  Delete account
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Container>

      {modals}

      {toastMessage && (
        <CustomToast
          setToastMessage={setToastMessage}
          message={toastMessage.message}
          result={toastMessage.result}
        />
      )}
    </>
  );
};

export default Profile;
