import { useAuth0 } from "@auth0/auth0-react";
import { Button, CloseButton, Modal } from "react-bootstrap";
import { ToastMessage } from "./CustomToast";

type DeleteReviewModalProps = {
  show: boolean;
  handleClose: () => void;
  reviewId?: string;
  setToastMessage: React.Dispatch<
    React.SetStateAction<ToastMessage | undefined>
  >;
};

const DeleteReviewModal = ({
  show,
  handleClose,
  reviewId,
  setToastMessage,
}: DeleteReviewModalProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

  const deleteReview = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: "htttp://hall-review.test.com",
      },
    });

    const response = await fetch(`${API_BASE_URL}/reviews/${reviewId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      setToastMessage({
        message: "Review successfully deleted.",
        result: "Success",
      });
    } else {
      setToastMessage({
        message: "Something went wrong. Unable to delete review.",
        result: "Failure",
      });
    }

    await new Promise((res) => setTimeout(res, 3000));
    window.location.replace("/profile");
  };

  return (
    <Modal
      show={show}
      style={{ borderRadius: 0 }}
      className="delete-review-modal profile-page-modal"
    >
      <Modal.Body className="m-2" style={{ borderRadius: 0 }}>
        <CloseButton
          onClick={() => handleClose()}
          className="close-modal-btn"
        />
        <h3 style={{ display: "inline-block" }}>Confirm | Delete Review</h3>

        <p className="mb-4">Are you sure you want to delete your review?</p>
        <Button variant="danger" onClick={() => deleteReview()}>
          Delete review
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteReviewModal;
