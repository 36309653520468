import { Button, CloseButton, Modal } from "react-bootstrap";

type DeleteAccountModalProps = {
  show: boolean;
  handleClose: () => void;
  deleteAccount: () => void;
};

const DeleteAccountModal = ({
  show,
  handleClose,
  deleteAccount,
}: DeleteAccountModalProps) => {
  return (
    <Modal
      show={show}
      style={{ borderRadius: 0 }}
      className="delete-account-modal profile-page-modal"
    >
      <Modal.Body className="m-2" style={{ borderRadius: 0 }}>
        <CloseButton
          onClick={() => handleClose()}
          className="close-modal-btn"
        />
        <h3 style={{ display: "inline-block" }}>Confirm | Delete Account</h3>

        <p className="mb-4">Are you sure you want to delete your account?</p>
        <Button variant="danger" onClick={() => deleteAccount()}>
          Delete account
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteAccountModal;
