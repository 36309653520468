import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import HallInfo from "../HallInfo";
import ImageBanner from "../ImageBanner";
import NavBar from "../NavBar";
import ReviewSection from "../ReviewSection";

export type Review = {
  id: string;
  hallId: string;
  userId: string;
  rating: number;
  title?: string;
  reviewText: string;
  author: string;
  createdDate: string;
  lastEdited: string;
};

type Hall = {
  id: string;
  name: string;
  bio: string;
  population: number;
  campusDistance: number;
  cost: number;
  latitude: number;
  longitude: number;
};

type HallPageProps = {
  view: "reviews" | "learn-more";
};

const HallPage = ({ view }: HallPageProps) => {
  const { id } = useParams();
  const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;
  const [queryString, setQueryString] = useState<string>(
    `${API_BASE_URL}/reviews/${id}`
  );

  const [hallData] = useFetch<Hall>(`${API_BASE_URL}/hall/GetHall/${id}`);
  const [reviewsData] = useFetch<Review[]>(queryString);

  // If the user selects a sort by preference, then sort accordingly
  const sortByHandler = (sortBy: string | undefined) => {
    switch (sortBy) {
      case "LATEST":
        setQueryString(
          `${API_BASE_URL}/reviews/${id}?SortBy=CreatedDateRecent`
        );
        break;
      case "HIGHEST_RATED":
        setQueryString(`${API_BASE_URL}/reviews/${id}?SortBy=RatingHighest`);
        break;
      case "LOWEST_RATED":
        setQueryString(`${API_BASE_URL}/reviews/${id}?SortBy=RatingLowest`);
        break;
      default:
        setQueryString(
          `${API_BASE_URL}/reviews/${id}?SortBy=CreatedDateRecent`
        );
        break;
    }
  };

  const [showReviews, setShowReviews] = useState<boolean>(view === "reviews");

  const [averageRating, setAverageRating] = useState<number | undefined>(
    undefined
  );
  const [reviews, setReviews] = useState<Review[]>([]);

  const switchViews = () => {
    setShowReviews((currentVal) => !currentVal);
  };

  console.log(hallData);

  useEffect(() => {
    if (!reviewsData) {
      return;
    }
    let avg = undefined;
    if (reviewsData.length > 0) {
      avg =
        reviewsData.reduce(
          (accumulator: number, current: Review) =>
            accumulator + current.rating,
          0
        ) / reviewsData.length;
    }
    if (avg) avg = Math.round(avg * 100) / 100;

    setAverageRating(avg);
    setReviews(reviewsData);
  }, [reviewsData]);

  return (
    <>
      <NavBar />

      {hallData && (
        <>
          <ImageBanner
            hallName={hallData.name}
            reviewAvg={averageRating}
            numReviews={reviewsData?.length}
            showReviews={showReviews}
            showReviewsCallback={switchViews}
          />

          {showReviews ? (
            <ReviewSection reviews={reviews} sortByHandler={sortByHandler} />
          ) : (
            <HallInfo
              title={hallData.name}
              bio={hallData.bio}
              population={hallData.population}
              cost={hallData.cost}
              distance={hallData.campusDistance}
              latitude={hallData.latitude}
              longitude={hallData.longitude}
            />
          )}
        </>
      )}
    </>
  );
};

export default HallPage;
