import {
  faBed,
  faClock,
  faMoneyCheckDollar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import HallMap from "./HallMap";

type HallInfoProps = {
  title: string;
  bio: string;
  population: number | undefined;
  cost: number | undefined;
  distance: number | undefined;
  latitude: number | undefined;
  longitude: number | undefined;
};

export const formatCostStr = (cost: number) => {
  var commaSeparatedCost = cost
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return "$" + commaSeparatedCost;
};

const HallInfo = ({
  title,
  bio,
  population,
  cost,
  distance,
  latitude,
  longitude,
}: HallInfoProps) => {
  return (
    <div className="hall-info-container">
      <h2>About</h2>
      {(population || cost) && (
        <div className="hall-facts mb-3">
          {population && (
            <span className="population me-3">
              <FontAwesomeIcon
                color={"#27374d"}
                icon={faBed}
                className="me-1"
              />
              {population} students
            </span>
          )}
          {cost && (
            <span className="cost me-3">
              <FontAwesomeIcon
                icon={faMoneyCheckDollar}
                color={"rgb(58, 148, 113)"}
                className="me-1"
              />
              {formatCostStr(cost)}
            </span>
          )}
          {distance && (
            <span className="distance">
              <FontAwesomeIcon
                icon={faClock}
                color={"#27374d"}
                className="me-1"
              />
              {distance} mins from campus
            </span>
          )}
        </div>
      )}

      <p className="hall-bio">{bio}</p>

      {latitude && longitude && (
        <HallMap title={title} latitude={latitude} longitude={longitude} />
      )}
    </div>
  );
};

export default HallInfo;
